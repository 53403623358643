import React, { Component, lazy } from 'react'
import PropTypes from 'prop-types'
import { Sidebar, Container, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router-dom'

import Header from '../../components/Header'
import CustomSidebar from '../../components/Sidebar'
import TopMenu from '../../components/TopMenu'
import HomeView from '../HomeView'
import ContactView from '../ContactView'
// import ComingSoon from '../ComingSoon'
import RequestForm from '../RequestForm'
import NotFound from '../NotFound'
import Products from '../products/Products'
import Stores from '../Stores'

import { toggleSidebar } from '../../store/sidebar'
import './CoreLayout.scss'

const Gallery = lazy(() => import('../Gallery'))

class CoreLayout extends Component {
  static propTypes = {
    visibleSidebar: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
  }

  hideSdebar = () => {
    if (this.props.visibleSidebar) {
      this.props.toggleSidebar()
    }
  }

  renderTopMenu() {
    const {
      location: { pathname },
    } = this.props

    if (pathname === '/') {
      return null
    }
    return (
      <Container className='top-menu-container'>
        <TopMenu inverted={false} size='small' pointing borderless />
      </Container>
    )
  }

  render() {
    const { visibleSidebar } = this.props

    return (
      <Switch>
        <Route
          path={['/request', '/заявка']}
          render={() => (
            <RequestForm
              title='Заявка за Ядкови Деликатеси'
              src='https://docs.google.com/forms/d/e/1FAIpQLScLH9iDSNCsqc7PuqNFVRisTtaWnKWbprbokqu11_x1QYhVDQ/viewform'
            />
          )}
        />

        <Route
          path={['/easter-request', '/заявка-великден']}
          render={() => (
            <RequestForm
              title='Заявка за Великден'
              src='https://docs.google.com/forms/d/e/1FAIpQLSeOSFkzQa4Ex18skTZ6ZxTaH3kGWCNB3He2vS2dbAh87TAROw/viewform'
            />
          )}
        />

        <Route
          path={['/sweet-christmas', '/сладка-коледа']}
          render={() => (
            <RequestForm
              title='Сладка Коледа'
              src='https://docs.google.com/forms/d/e/1FAIpQLSc9rp24yLDQA9TBnK9MIaVBrONV5PzakGcSH83yF6OaExp6lw/viewform'
            />
          )}
        />

        <Route
          path={'/partners'}
          render={() => {
            window.location.href =
              'https://www.facebook.com/livingfood.bg/posts/pfbid0X1X1sWfxMyVX3wVGe5GTXY42jigw8Sok31QBybWxBuZotqJQc3opYpTFhWjvgdJ8l'
          }}
        />

        <Route>
          <Sidebar.Pushable>
            <CustomSidebar />

            <Sidebar.Pusher dimmed={visibleSidebar} onClick={this.hideSdebar}>
              <Header />
              {this.renderTopMenu()}

              <Switch>
                <Route path='/' exact component={HomeView} />
                <Route path='/products/:name?' component={Products} />
                <Route path='/stores' component={Stores} />
                <Route path='/gallery' component={Gallery} />
                <Route path='/contact' component={ContactView} />
                <Route component={NotFound} />
              </Switch>

              <Divider hidden />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Route>
      </Switch>
    )
  }
}

export default connect(
  (state) => ({ visibleSidebar: state.sidebar.visible }),
  (dispatch) => bindActionCreators({ toggleSidebar }, dispatch)
)(CoreLayout)
